import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Index = ({ width, height, title, img_url }) => (
  <div
    style={{
      width: width,
      height: height,
    }}
    className="d-flex flex-column justify-content-center align-items-center ads-container-vertical"
  >
    {img_url ? (
      <img
        src={img_url}
        style={{ objectFit: "cover", width: "100%", height: "100%" }}
      />
    ) : (
      <>
        <StaticImage
          src="../../../../../static/images/ic_logo_gakeslab.png"
          alt="logo"
          placeholder="blurred"
        />
        <span className="title">{title}</span>
        <span className="size">{`${width} X ${height} PIXEL`}</span>
      </>
    )}
  </div>
)

export default Index
